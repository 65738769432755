import axios from 'axios'
import store from '@/store'
import apiUtil from './apiUtil'

// Athenable-Dependency here
// todo connect to external api
export default {

  getMyPublications (callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    const apiUrl = apiUriPrefix.concat('api?pub_load=y&my_publications=y&athenable_tk=', token)
    //console.log('calling:', apiUrl)
   
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetMyPublications(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetMyPublications(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getPublication (issn, callbackComponet, loadingContainer) {
    //bypass spinner since its in conflict with other concurent queries
    //apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&detail=y&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    
    console.log('calling api getPublication :', apiUrl, loadingContainer)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('backFromGetPublication', response.data)
        callbackComponet.backFromGetPublication(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetPublication(apiUtil.handleError(error))
      })
      .finally(() => {
        //apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getTaxonomy (issn, includeAudit, callbackComponet, loadingContainer) {
    //bypass spinner since its in conflict with other concurent queries
    //apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&load_taxonomy=y&include_audit=:include_audit&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':include_audit', includeAudit)
    
    console.log('calling api getTaxonomy:', apiUrl, loadingContainer)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('backFromGetTaxonomy', response.data)
        callbackComponet.backFromGetTaxonomy(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetTaxonomy(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getIssueDates (issn, volume, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issue_dates=y&volume=:volume&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':volume', volume)
    
    console.log('calling api getIssueDates :', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetIssueDates(response.data)
      })
      .catch(error => {
        console.log('resonse error  2222', error)
        callbackComponet.backFromGetIssueDates(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getIssuesInProduction (issn, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issues_in_production=y&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    
    console.log('calling api getIssuesInProduction :', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetIssuesInProduction(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetIssuesInProduction(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getOpenIssues (issn, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&open_issues=y&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    
    console.log('calling api getOpenIssues :', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetOpenIssues(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetOpenIssues(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getCrossrefArticles (issn, volume, issue, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&crossref_articles=y&issn=:issn&volume=:volume&issue=:issue&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':volume', volume)
    apiUrl = apiUrl.replace(':issue', issue)
    
    console.log('calling api getCrossrefArticles :', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetCrossrefArticles(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetCrossrefArticles(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
 
  getPublicationIssue (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issue=y&issn=:issn&issue_date=:issue_date&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':issue_date', queryParams.issue_date)

    if (queryParams.issue_begin_date) {
      apiUrl = apiUrl.concat('&issue_begin_date=', queryParams.issue_begin_date)
    }
    if (queryParams.query_pubdate_online) {
      apiUrl = apiUrl.concat('&query_pubdate_online=y')
    }

    console.log('calling getPublicationIssue:', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetPublicationIssue(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetPublicationIssue(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getPublicationAdminIssue (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issue_admin=y&issn=:issn&file_status=:file_status')
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling getPublicationAdminIssue:', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetPublicationAdminIssue(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetPublicationAdminIssue(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getArticleXMLDetail (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&get_article_xml_detail=y&issn=:issn&file_name=:file_name&file_status=:file_status')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    if (queryParams.web_view === 'y') {
      apiUrl = apiUrl.concat('&web_view=y')
    }
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetArticleXMLDetail(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetArticleXMLDetail(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getArticleXMLVersions (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&get_article_xml_versions=y&issn=:issn&file_name=:file_name&file_status=:file_status')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetArticleXMLVersions(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetArticleXMLVersions(apiUtil.handleError(error))
      })
      .finally(() => {
        //apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  compareVersions (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&compare_versions=y&issn=:issn&file_name=:file_name&file_status=:file_status&version_1_id=:version_1_id&version_2_id=:version_2_id')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.replace(':version_1_id', queryParams.version_1_id)
    apiUrl = apiUrl.replace(':version_2_id', queryParams.version_2_id)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromCompareVersions(response.data)
      })
      .catch(error => {
        callbackComponet.backFromCompareVersions(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  aiApiUrlOn(queryParams, rname) {
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?openAI_api=y&:rname=y&issn=:issn&file_name=:file_name&file_status=:file_status')
    apiUrl = apiUrl.replace(':rname', rname)
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    return apiUrl
  },

  keywordsFromArticleRequest (queryParams, callbackComponet, loadingContainer, showSpinner) {
    if (showSpinner) apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const apiUrl = this.aiApiUrlOn(queryParams, 'keywords_from_article')
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromKeywordsFromArticleRequest(response.data)
      })
      .catch(error => {
        callbackComponet.backFromKeywordsFromArticleRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        if (showSpinner)  apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  imageForArticleRequest (queryParams, callbackComponet, loadingContainer, showSpinner) {
    if (showSpinner) apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const apiUrl = this.aiApiUrlOn(queryParams, 'image_for_article')
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromImageForArticleRequest(response.data)
      })
      .catch(error => {
        callbackComponet.backFromImageForArticleRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        if (showSpinner)  apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  subjectAreasRequest (queryParams, callbackComponet, loadingContainer, showSpinner) {
    if (showSpinner) apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const apiUrl = this.aiApiUrlOn(queryParams, 'subject_areas')
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromSubjectAreasRequest(response.data)
      })
      .catch(error => {
        callbackComponet.backFromSubjectAreasRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        if (showSpinner) apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  articleShortSummaryRequest (queryParams, callbackComponet, loadingContainer, showSpinner) {
    if (showSpinner) apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const apiUrl = this.aiApiUrlOn(queryParams, 'article_short_summary')
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromArticleShortSummaryRequest(response.data)
      })
      .catch(error => {
        callbackComponet.backFromArticleShortSummaryRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        if (showSpinner) apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  articleMainFndingsRequest (queryParams, callbackComponet, loadingContainer, showSpinner) {
    if (showSpinner) apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const apiUrl = this.aiApiUrlOn(queryParams, 'article_main_findings')
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromArticleMainFndingsRequest(response.data)
      })
      .catch(error => {
        callbackComponet.backFromArticleMainFndingsRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        if (showSpinner) apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  articlePressReleaseRequest (queryParams, callbackComponet, loadingContainer, showSpinner) {
    if (showSpinner) apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const apiUrl = this.aiApiUrlOn(queryParams, 'article_press_release')
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromArticlePressReleaseRequest(response.data)
      })
      .catch(error => {
        callbackComponet.backFromArticlePressReleaseRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        if (showSpinner) apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  snapshotReviewSession(queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&snapshot_review_session=y&issn=:issn&file_name=:file_name&file_status=:file_status')

    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    //console.log('calling tttttsnapshotReviewSession:', apiUrl)
    
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromSnapshotReviewSession(response.data)
      })
      .catch(error => {
        callbackComponet.backFromSnapshotReviewSession(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getArticleReviewSessions(issn, vol, issue, fileName, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&article_review_sessions=y&issn=:issn&volume=:volume&issue=:issue&file_name=:file_name')
    
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':volume', vol)
    apiUrl = apiUrl.replace(':issue', issue)
    apiUrl = apiUrl.replace(':file_name', fileName)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetArticleReviewSessions(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetArticleReviewSessions(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getArticleReview(queryParams,  callbackComponet, loadingContainer) {

    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&article_review=y&issn=:issn&volume=:volume&issue=:issue&file_name=:file_name&session_id=:session_id&reviewer_id=:reviewer_id')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':volume', queryParams.volume)
    apiUrl = apiUrl.replace(':issue',  queryParams.issue)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':session_id', queryParams.session_id)
    apiUrl = apiUrl.replace(':reviewer_id', queryParams.reviewer_id)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetArticleReview(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetArticleReview(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  addReview(issn, vol, issue, fileName, sessionId, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&add_review=y&issn=:issn&volume=:volume&issue=:issue&file_name=:file_name&sessionId=:sessionId')

    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':volume', vol)
    apiUrl = apiUrl.replace(':issue', issue)
    apiUrl = apiUrl.replace(':file_name', fileName)
    apiUrl = apiUrl.replace(':sessionId', sessionId)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling tttttaddReview:', apiUrl)
    
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromAddReview(response.data)
      })
      .catch(error => {
        callbackComponet.backFromAddReview(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  updateAnnotationNote(queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', queryParams.issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'update_annotation_note',
      volume: queryParams.volume,
      issue: queryParams.issue,
      file_name: queryParams.file_name,
      session_id: queryParams.session_id,
      reviewer_id: queryParams.reviewer_id,
      notes_record: queryParams.notes_record,
      article_json: queryParams.article_json,
    }

    console.log('wwww', typeof queryParams.article_json)
    console.log('calling updateAnnotationNote:', apiUrl, requestObj)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromUpdateAnnotationNote(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromUpdateAnnotationNote(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  replaceCatogoriesWithTaxonomySelections (issn, volume, issue, fileName,  taxonomySelections, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    const requestObj = {
      action: 'replace_article_catogories',
      volume,
      issue,
      file_name: fileName,
      taxonomySelections
    }
    console.log('ggggggggggggggg', requestObj)
    console.log('calling replaceCatogoriesWithTaxonomySelections:', apiUrl)
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromReplaceCatogoriesWithTaxonomySelections(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromReplaceCatogoriesWithTaxonomySelections(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  saveTaxonomyEntry (issn, entryModel, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    const requestObj = {
      action: 'save_taxonomy_entry',
      entryModel
    }
    console.log('ggggggggggggggg', requestObj)
    console.log('calling saveTaxonomyEntry:', apiUrl)
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromSaveTaxonomyEntry(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromSaveTaxonomyEntry(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  
  loadArticleDropRoot (issn, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&load_article_drop_root=y&issn=:issn')
    
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromLoadArticleDropRoot(response.data)
      })
      .catch(error => {
        callbackComponet.backFromLoadArticleDropRoot(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  
  transferDropArticleToProduction(issn, dois, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&transfer_drop_article_to_prod=y&issn=:issn&dois=:dois')
    
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':dois', dois)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    
    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromTransferDropArticleToProduction(response.data)
      })
      .catch(error => {
        callbackComponet.backFromTransferDropArticleToProduction(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  resolveXmlOnIntake (issn, action,  xml, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?art_load=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    const requestObj = {
      action,
      xml
    }
    console.log('calling resolveXmlOnIntake:', apiUrl)
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromResolveXmlOnIntake(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromResolveXmlOnIntake(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getJobList(issn, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?report=y&list_jobs=y&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)

    // console.log('calling ttttt:', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetJobList(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetJobList(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getIssueIntakeZips(issn, filterVolume, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?intake=y&issue_intake_list=y&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':issn', issn)
    if (filterVolume !== null) {
      apiUrl = apiUrl.concat('&vol=', filterVolume)
    }

    console.log('calling  tt getIssueIntakeZips:', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetIssueIntakeZips(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetIssueIntakeZips(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  startIssueIntake (issn, zipList, intakeToIssueArchive, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?intake=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.concat('&athenable_tk=', token)
    const requestObj = {
      action: 'start_issue_intake',
      zip_list: zipList,
      move_to_published: intakeToIssueArchive,
    }
    console.log('calling startIssueIntake:', apiUrl)
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromStartIssueIntake(response.data)
      })
      .catch(error => {
        console.log('response 222', error)
        callbackComponet.backFromStartIssueIntake(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getStagedOnWebHost (issn, volume, issue, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?report=y&staged_on_web_host=y&volume=:volume&issue=:issue&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':volume', volume)
    apiUrl = apiUrl.replace(':issue', issue)
    apiUrl = apiUrl.replace(':issn', issn)

    // console.log('calling ttttt:', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetStagedOnWebHost(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetStagedOnWebHost(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  searchByDOI (doi, issn, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?report=y&search_by_doi=y&doi=:doi&issn=:issn&athenable_tk=', token)
    apiUrl = apiUrl.replace(':doi', doi)
    apiUrl = apiUrl.replace(':issn', issn)

    console.log('calling callbackComponet:', apiUrl)
     
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromSearchByDOI(response.data)
      })
      .catch(error => {
        callbackComponet.backFromSearchByDOI(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  searchQueryRequest (requestObj, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = ''
    if (requestObj.exportQuery === 'y') {
      apiUrl = apiUriPrefix.concat('api?report_export=y&issn=:issn')
    } else {
      apiUrl = apiUriPrefix.concat('api?report=y&issn=:issn')
    }

    apiUrl = apiUrl.replace(':issn', requestObj.issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling: ', requestObj.action, apiUrl, requestObj)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromSearchQueryRequest(response.data)
      })
      .catch(error => {
        console.log('Error on search Query Request', error)
        callbackComponet.backFromSearchQueryRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  updateAudit (issn, volume, issue, doiList, issueFileList, changeActivityName, notesAndNotification, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'update_audit',
      volume,
      issue,
      doi_list: doiList,
      issue_file_list: issueFileList,
      changeActivityName,
      notesAndNotification,
    }

    console.log('calling updateAudit:', apiUrl, requestObj)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromUpdateAudit(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromUpdateAudit(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  dropPointAudit (issn, volume, issue, doiList, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?pub_load=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'drop_point_audit',
      volume,
      issue,
      doi_list: doiList,
    }

    console.log('calling drop_point_audit:', apiUrl, requestObj)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromDropPointAudit(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromDropPointAudit(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
 
  promoteToProductionIssue (issn, volume, issue, doiList, notesAndNotification, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?distro=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'promote_to_issue',
      volume,
      issue,
      doi_list: doiList,
      notesAndNotification,
    }

    console.log('calling promoteToProductionIssue:', apiUrl)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromPromoteToProductionIssue(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromPromoteToProductionIssue(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  stageOnWebHost (issn, volume, issue, doiList, issueFileList, notesAndNotification, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?distro=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'stage_on_web_host',
      volume,
      issue,
      doi_list: doiList,
      issue_file_list: issueFileList,
      notesAndNotification,
    }

    console.log('calling stageOnWebHost:', apiUrl)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromStageOnWebHost(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromStageOnWebHost(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  packageStaging (issn, volume, issue, doiList, issueFileList, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?distro=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'package_staging',
      volume,
      issue,
      doi_list: doiList,
      issue_file_list: issueFileList,
    }

    console.log('calling package_staging:', apiUrl)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromPackageStaging(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromPackageStaging(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  moveToPublished (issn, volume, issue, doiList, issueFileList, notesAndNotification, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?intake=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'move_to_published',
      volume,
      issue,
      doi_list: doiList,
      issue_file_list: issueFileList,
      notesAndNotification,
    }

    console.log('calling moveToPublished:', apiUrl)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromMoveToPublished(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromMoveToPublished(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  intakeManuscript (issn, fileName, orcidId, orcidName, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    //const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('submissions?submissions=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&subms_tk=', '123')

    const requestObj = {
      action: 'intake_manuscript',
      file_name: fileName,
      orcid_id: orcidId,
      orcid_name: orcidName,
    }
    console.log('calling intakeManuscript:', apiUrl)
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromIntakeManuscript(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromIntakeManuscript(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  submitManuscriptIntake(issn, fileName, orcidId, orcidName, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    //const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('submissions?submissions=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&subms_tk=', '123')

    const requestObj = {
      action: 'submit_manuscript_intake',
      file_name: fileName,
      orcid_id: orcidId,
      orcid_name: orcidName,
    }
    console.log('calling submit_manuscript_intake:', apiUrl)
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromSubmitManuscriptIntake(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromSubmitManuscriptIntake(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  reopenInProduction (issn, volume, issue, doiList, issueFileList, notesAndNotification, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?intake=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'reopen_in_production',
      volume,
      issue,
      doi_list: doiList,
      issue_file_list: issueFileList,
      notesAndNotification,
    }

    console.log('calling reopenInProduction:', apiUrl)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromReopenInProduction(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromReopenInProduction(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  sendToDistributions (issn, inProduction, volume, issue, doiList, distros, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?distro=y&issn=:issn')
    apiUrl = apiUrl.replace(':issn', issn)

    apiUrl = apiUrl.concat('&athenable_tk=', token)

    const requestObj = {
      action: 'send_to_distributions',
      in_production: inProduction,
      volume,
      issue,
      doi_list: doiList,
      distros,
    }

    console.log('calling sendToDistributions:', apiUrl)
     
    axios
      .post(apiUrl, requestObj)
      .then(response => {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromSendToDistributions(response.data)
      })
      .catch(error => {
        console.log('resonse 222', error)
        callbackComponet.backFromSendToDistributions(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
}
